import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/src/components/layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "general-information",
      "style": {
        "position": "relative"
      }
    }}>{`GENERAL INFORMATION`}</h1>
    <SEO title={props.pageContext.frontmatter.title} mdxType="SEO"></SEO>
    <h2 {...{
      "id": "shipping-and-delivery---delivery-zones",
      "style": {
        "position": "relative"
      }
    }}>{`Shipping and Delivery - Delivery Zones`}</h2>
    <p>{`Products will be shipped to the delivery address(es) specified during the ordering procedure provided such address(es) is(are) located within the Delivery Zone. Any delivery address located outside of the Delivery Zone will be refused during the ordering procedure.`}</p>
    <p>{`Products purchased via the Website may only be delivered to a physical street address within the Delivery Zone.`}</p>
    <p><strong parentName="p">{`Chrome Hearts will not process any order for which a post office box or APO/FPO address is provided.`}</strong></p>
    <h2 {...{
      "id": "shipping-and-delivery---shipping-costs",
      "style": {
        "position": "relative"
      }
    }}>{`Shipping and Delivery - Shipping costs`}</h2>
    <p>{`Standard Shipping (Ground) is provided for all US orders, except for furniture or any other specified specialty item. Some furniture, specialty items and customized products may be subject to longer delivery times and delivery delays.If your order is not able to ship immediately, Customer Service will contact you to provide more details and an estimated shipping date. We do not currently offer 2 day, Overnight, Priority Overnight or Saturday shipping at this time. Delivery time for Standard Shipping (Ground) is expected to be 3 to 7 business days. The delivery time runs from when your order has been confirmed, except for furniture, certain specialty items and customized products for which the delivery times run from when the products become available.`}</p>
    <p>{`Delivery costs depend on the value of your order:`}</p>
    <ul>
      <li parentName="ul">{`For an order value <$250.00, Standard Shipping (Ground) will be USD $10.00`}</li>
      <li parentName="ul">{`For an order value between $250.01 and $500, Standard Shipping (Ground) will be USD $15.00`}</li>
      <li parentName="ul">{`For an order value between $500.01 and $1000, Standard Shipping (Ground) will be USD $20.00`}</li>
      <li parentName="ul">{`For an order value between $1000.01 and $2000, Standard Shipping (Ground) will be USD $25.00`}</li>
      <li parentName="ul">{`For an order value above $2000.01, Standard Shipping (Ground) will be USD $30.00`}</li>
    </ul>
    <p><strong parentName="p">{`An adult signature will be required on all orders.`}</strong></p>
    <p>{`You will receive an email containing your Tracking Number once your package has been shipped.
We will inform you of the shipping costs in the order summary displayed on the Website before you confirm your order. This amount will be payable by you in addition to the price, including taxes, of the products ordered.`}</p>
    <h2 {...{
      "id": "delivery-time---problems-of-delivery",
      "style": {
        "position": "relative"
      }
    }}>{`Delivery Time - Problems of delivery`}</h2>
    <p>{`Any failure to deliver or late delivery exceeding eight (8) business days should be reported to Customer Service as soon as possible. In order for your claim to be accepted, please notify us within ten (10) business days from the date of confirmation of your order.`}</p>
    <p>{`You should check that your shipment is correct on delivery. If there are any discrepancies in your order when the products are delivered to you, you should note the nature of the potential discrepancy (e.g., open package, damaged product, etc.) by hand, if possible, on the delivery notice, and sign.`}</p>
    <p>{`If the products do not conform to your order or you are not satisfied with them, you may return them for an exact replacement or request a refund according to the terms and conditions contained in the section entitled "Returns, Replacements & Refunds".  See also Specific Terms and Conditions of Sale as applicable to your order.`}</p>
    <h2 {...{
      "id": "returns-replacements--exchanges",
      "style": {
        "position": "relative"
      }
    }}>{`Returns, Replacements & Exchanges`}</h2>
    <h3 {...{
      "id": "principles-and-procedure-of-return",
      "style": {
        "position": "relative"
      }
    }}>{`Principles and procedure of return:`}</h3>
    <p>{`With the exception of furniture, certain specialty items and/or customized items, you may return any product ordered on the Website for exact replacement or refund within thirty (30) calendar days from the delivery date subject the terms and limitations set out below.`}</p>
    <p>{`Returned products must be in their perfectly saleable, original condition and if applicable, packaging, together with their purchase invoice or a copy in the case of a partial return. Chrome Hearts has sole discretion in determining whether the products purchased via the Website are in original condition when returned.`}</p>
    <p>{`Returning a product for exact replacement or refund is free of charge as long as you return the products to us via our carrier by using our system of pre-paid return labels. Contact Customer Service to request a return or exact replacement. Customer Service will provide instructions to complete your return. All returns will be shipped to the following address:`}</p>
    <pre><code parentName="pre" {...{}}>{`Chrome Hearts
Attn: Returns Processing
915 N Mansfield Ave
Hollywood, CA 90038
`}</code></pre>
    <p>{`If you choose a return shipping method outside of the approved shipping carrier provided by Customer Service, Chrome Hearts will not be responsible or liable if any item you return to the Website is lost, misdirected or delivered late insofar as you have to bear the risks of the shipping you have chosen.`}</p>
    <p><strong parentName="p">{`Returns conditions applicable to fragrance, makeup and beauty Products:`}</strong>{` Fragrance, makeup and beauty Products must not be opened and must be returned in their original packaging. If the transparent film has been opened or removed or if the Product has been used, the item will not be accepted for return or exchange. If a product is defective or has been damaged during delivery, customer must ensure that the bottle is still hermetically sealed (if this is not the case, contact Customer Service).`}</p>
    <h3 {...{
      "id": "exact-replacement",
      "style": {
        "position": "relative"
      }
    }}>{`Exact Replacement`}</h3>
    <p>{`You may exchange an item for the exact replacement of that item, which may vary by size if applicable.
If you replace an item for another item of greater value due to size or configuration, you shall pay the difference in price. If you request an exact replacement for an item of lesser value due to size or configuration, you will be refunded within ten (10) business days of receipt of the item returned by crediting your debit or credit card.`}</p>
    <p>{`Please contact `}<a parentName="p" {...{
        "href": "mailto:contact@chromehearts.com"
      }}>{`Customer Service`}</a>{` to receive the return instructions.`}</p>
    <p>{`If you replace an item, the shipping costs for the first delivery will not be refunded. However, you will not be charged for the shipping costs of the second delivery.
Only one exact replacement per item will be honored.`}</p>
    <h2 {...{
      "id": "refunds",
      "style": {
        "position": "relative"
      }
    }}>{`Refunds`}</h2>
    <p>{`If you request a refund, Chrome Hearts will use commercially reasonable efforts to ensure that any refund will be made within ten (10) business days of receipt of the product returned by crediting your debit or credit card. Under no circumstances will you be granted an electronic credit usable on the Website, nor will the Website issue a store credit for use in a Chrome Hearts store.`}</p>
    <h3 {...{
      "id": "limitations-to-exact-replacements-and-refunds",
      "style": {
        "position": "relative"
      }
    }}>{`Limitations to exact replacements and refunds:`}</h3>
    <p>{`We do not currently replace or allow refunds for product purchased in Chrome Hearts stores via the Website. Likewise, Chrome Hearts stores do not offer refunds or replacements on any product purchased on the Website. Under no circumstances will it be possible to credit your debit or credit card by a Chrome Hearts store. Crediting of the debit or credit card on which the product(s) were purchased will occur once Customer Service validates the condition of the return and eligibility for refund or replacement in its sole discretion.`}</p>
    <p><strong parentName="p">{`A product ordered on the Website that has been personalized cannot be returned for replacement or refund.`}</strong></p>
    <p>{`All furniture orders are custom made and cannot be returned, replaced, or canceled. No reimbursement will be made.`}</p>
    <p>{`Only one (1) return or replacement may be processed per item on an order.`}</p>
    <h3 {...{
      "id": "for-furniture",
      "style": {
        "position": "relative"
      }
    }}>{`FOR FURNITURE`}</h3>
    <p><em parentName="p">{`Terms of Delivery`}</em></p>
    <p>{`Products will be delivered by an independent white glove moving company. Delivery fees and times may vary. Customer Service will contact you upon confirmation of the furniture order to review approximate fees, wait times, schedule delivery, and arrange payment of delivery fees.`}</p>
    <p><strong parentName="p">{`No in-store pick up is available for furniture.`}</strong></p>
    <p><em parentName="p">{`No Replacement or Return of Furniture`}</em></p>
    <p>{`All sales are final. Furniture may not be returned for refund nor replaced.`}</p>
    <p><em parentName="p">{`Claims`}</em></p>
    <p>{`Any claims or objections regarding furniture orders must be made immediately to the carrier upon receipt of the furniture product and be noted clearly on the bill of lading. In the case of latent damage, such claims must be submitted to Chrome Hearts Corporate Office, 915 N Mansfield Ave, Hollywood, CA 90038, attention: Customer Service, within five (5) business days after delivery confirmation.`}</p>
    <h2 {...{
      "id": "product-purchase-limitations",
      "style": {
        "position": "relative"
      }
    }}>{`Product Purchase Limitations`}</h2>
    <p>{`To ensure better service and better availability of our products, Chrome Hearts may limit the quantity of products that can be purchased for any item, on any single order or across multiple orders to a customer or address. Chrome Hearts reserves in its sole discretion the right to determine what quantity restrictions will be put in place for an item, order, or orders. To ensure the observance of these restrictions, Chrome Hearts reviews your order and processes your purchase history. In case of non-observance, our Customer Service or Store Associates may contact you and may refuse further transactions with you, for a period of 2 years from your last purchase. For more information please read our `}<a parentName="p" {...{
        "href": "/privacy-policy"
      }}>{`Privacy Policy`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      